import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { titlecase } from "../utils/common";
import { Triangle } from "react-loader-spinner";

const corporateRoles = [
  { value: "ccooperate", label: "Full Access" },
  { value: "crcallcenter", label: "Call Center Representative" },
  { value: "cdispatcher", label: "Transport Coordinator" },
  { value: "corporateapp", label: "Corporate App" },
];

export default function StaffList(props) {
  let navigate = useNavigate();
  const [staffList, setStaffList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const { getToken, user } = useAuth();

  const getStaffList = async () => {
    const token = JSON.parse(getToken());
    const corporateId = user.cooperate ? user.cooperate._id : user._id;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}corporateapps?cooperate=${corporateId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );

      const data = await response.json();
      setStaffList(data);
      setFilteredList(data);
      //   setData(data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  function getRoles(roles) {
    const r = roles.map((rl) => {
      const role = corporateRoles.find((r1) => r1.value === rl);
      return role ? role.label : rl;
    });
    return r.join(", ");
  }

  function editStaff(id) {
    navigate(`/staff/${id}/edit`);
  }

  useEffect(() => {
    if (user) getStaffList();
  }, [user]);

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = staffList.filter((item) => {
        return item.displayName.toLowerCase().includes(keyword.toLowerCase());
      });
      setFilteredList(results);
    } else {
      setFilteredList(staffList);
    }
  };

  return (
    <>
      {filteredList ? (
        <div className="px-4 sm:px-6 lg:px-8 py-4 overflow-y-auto">
          <div className="flex flex-wrap justify-between items-center gap-4">
            <div>
              <Link
                to="/staff/add"
                className="btn-primary"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
              >
                {/* <PlusIcon className="mr-3 h-5 w-5 text-main-primary" /> */}
                Add
              </Link>
              {/* <a
                            href="/staff/add"
                            type="button"
                            className="btn-primary"
                        >
                            Add Staff
                        </a> */}
            </div>
            <div className="flex-1 flex justify-center">
              <span className="text-main-light text-sm whitespace-nowrap">
                {/* Showing {{(staffList | filter:search).length}} entries */}
              </span>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <div className="relative">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <svg
                    className="mr-3 h-4 w-4 text-main-light"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="pl-9"
                  placeholder="Search by name"
                  autoComplete="off"
                  onChange={filter}
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-main-base md:rounded-lg ">
                  <table className="min-w-full divide-y divide-main-base">
                    <thead className="bg-main-base-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 sticky top-0 z-10 text-left text-xs font-semibold text-main-dark sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 sticky top-0 z-10 text-left text-xs font-semibold text-main-dark"
                        >
                          Contact No.
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 sticky top-0 z-10 text-left text-xs font-semibold text-main-dark"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 sticky top-0 z-10 text-left text-xs font-semibold text-main-dark"
                        >
                          Code
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 sticky top-0 z-10 text-left text-xs font-semibold text-main-dark"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-main-base bg-white">
                      {filteredList.map((staff, index) => (
                        <tr key={staff._id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center gap-2">
                              <div className="h-10 w-10 flex-shrink-0 object-cover overflow-hidden rounded-full bg-main-imageplaceholder">
                                {/* staff.profileImageURL ? staff.profileImageURL : 'assets/images/users/profile_placeholder.png' */}

                                <img
                                  className="h-full w-full rounded-full object-cover"
                                  src={
                                    staff.profileImageURL
                                      ? staff.profileImageURL
                                      : "assets/images/placeholders/driver_placeholder.png"
                                  }
                                  alt="profile_image"
                                />

                                <svg
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                  className="h-full w-full text-main-light"
                                >
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"></path>
                                </svg>
                              </div>
                              <div>
                                <div className="font-medium text-main-dark">
                                  {staff.displayName}
                                </div>
                                <div className="text-main-light">
                                  {staff.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-main-light">
                            {staff.contactNumber}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-main-light">
                            {getRoles(staff.roles)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-main-light">
                            {staff.cooperateCode}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-main-light">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {" "}
                              {titlecase(staff.accountStatus)}
                            </span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              onClick={() => editStaff(staff._id)}
                              className="px-2 text-main-primary hover:text-main-primary-hover hover:cursor-pointer duration-500 ease-linear"
                            >
                              <PencilSquareIcon className="w-5 h-5" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="fixed inset-0 grid place-content-center bg-white">
          <Triangle
            height="100"
            width="100"
            color="#749671"
            ariaLabel="loading"
          />
        </div>
      )}
    </>
  );
}
