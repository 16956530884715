import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function TripNotes(props) {
  const breakString = (str) => {
    if (!str) return "";
    return str.replace(/\n/g, "<br>");
  };

  return (
    <Transition.Root show={props.isNoteShow} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setIsNoteShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-main-light bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-main-dark">
                          Notes
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-main-light hover:text-main-light focus:outline-none focus:ring-2 focus:ring-main-primary focus:ring-offset-2"
                            onClick={() => props.setIsNoteShow(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-4">
                      <div>
                        <h4 className="font-medium text-sm text-main-dark">
                          Company/Driver Notes:
                        </h4>
                        {/* <span className=" text-sm  text-main-light mt-2 ">
                          {props.selectedTrip.companyNote
                            ? props.selectedTrip.companyNote
                            : "N/A"}
                        </span> */}
                        <span
                          className=" text-sm  text-main-light mt-2 "
                          dangerouslySetInnerHTML={{
                            __html: breakString(props.selectedTrip.companyNote),
                          }}
                        >
                          {/* {props.selectedTrip.companyNote
                            ? props.selectedTrip.companyNote
                            : "N/A"} */}
                        </span>
                      </div>
                      {/* <div>
                        <h4 className="font-bold text-main-dark">
                          Dispatcher Notes
                        </h4>
                        <span className="text-lg text-main-light mt-2 ">
                          {props.selectedTrip.dispatchNoteTwo
                            ? props.selectedTrip.dispatchNoteTwo
                            : "N/A"}
                        </span>
                      </div> */}
                      {props.selectedTrip &&
                        props.selectedTrip.changeReason &&
                        props.selectedTrip.changeReason.length && (
                          <div>
                            <h4 className="font-medium text-sm text-main-dark">
                              Cancel Reason:
                            </h4>
                            <span className=" text-sm  text-main-light mt-2 ">
                              {props.selectedTrip.changeReason
                                ? props.selectedTrip.changeReason
                                : "N/A"}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
