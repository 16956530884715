import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Controller, useForm } from "react-hook-form";
import useAuth from "../hooks/useAuth";
import ConfirmationModal from "./ConfirmationModal";

export default function CancelledReason(props) {
  const { getToken, user } = useAuth();

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [message, setMessage] = useState("Are you sure you want to cancel this trip?");
  const [cancelReason, setCancelReason] = useState("");

  const onSubmit = async (data) => {
    setIsConfirmationModalOpen(true);
  };

  const handleRadioChange = (value) => {
    setValue('changeReason', value);
  };

  const closeModal = () => {
    props.setIsCancelledReason(false);
    reset();
  };

  const handleConfirm = () => {
    setIsConfirmationModalOpen(false); // Close the modal
    const changeReason = getValues("changeReason");
    cancelRequest({changeReason});
  };
  
  const handleCancel = () => {
      setIsConfirmationModalOpen(false); // Close the modal
  };
  
  const cancelRequest = async (payload) => {
    try {
 
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}assigns/${props.selectedTrip._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${JSON.parse(getToken())}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const jsonData = await response.json();
      if (jsonData) {
        props.onCancelled(jsonData);
        closeModal();
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Transition.Root show={props.isCancelledReason} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={props.setIsCancelledReason}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-main-light bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-main-dark">
                          Reason
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-main-light hover:text-main-light focus:outline-none focus:ring-2 focus:ring-main-primary focus:ring-offset-2"
                            onClick={() => closeModal()}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-4">
                      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
                        <fieldset>
                          <legend className="sr-only">Notifications</legend>
                          <div className="space-y-3">
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="memberNoShow"
                                  aria-describedby="comments-description"
                                  name="cancelled_reason"
                                  type="radio"
                                  value="Member No Show, CAD"
                                  onChange={() => handleRadioChange('Member No Show, CAD')}
                                  checked={watch('changeReason') === 'Member No Show, CAD'}
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="memberNoShow"
                                  className="text-xs font-medium text-gray-900"
                                >
                                  Member No Show, CAD
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="memberCalled"
                                  aria-describedby="candidates-description"
                                  name="cancelled_reason"
                                  type="radio"
                                  value="Member Called"
                                  onChange={() => handleRadioChange('Member Called')}
                                  checked={watch('changeReason') === 'Member Called'}
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="memberCalled"
                                  className="text-xs font-medium text-gray-900"
                                >
                                  Member Called
                                </label>
                              </div>
                            </div>
                            <div className="relative flex items-start">
                              <div className="flex h-6 items-center">
                                <input
                                  id="BrokerInsuranceCalled"
                                  aria-describedby="offers-description"
                                  name="cancelled_reason"
                                  type="radio"
                                  value="Broker/Insurance Called"
                                  onChange={() => handleRadioChange('Broker/Insurance Called')}
                                  checked={watch('changeReason') === 'Broker/Insurance Called'}
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor="BrokerInsuranceCalled"
                                  className="text-xs font-medium text-gray-900"
                                >
                                  Broker/Insurance Called
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="relative">
                          <label
                            htmlFor="otherReason"
                            className="block text-sm font-medium text-main-secondary"
                          >
                            Driver Notes
                          </label>
                          <div className="mt-1">


                            <Controller
                              name="changeReason"
                              rules={{ required: true }}
                              control={control}
                              render={({ field }) => <textarea  className="resize-none h-24" {...field} />}
                            />

                          </div>
                          {errors?.changeReason && (<p className="mt-2 text-xs text-main-danger">
                            Please choose any above reason
                          </p>)}

                          {props.isWarningAllow && <p className="mt-2 font-normal text-sm text-red-500">Note: This Cancellation is subject to a fee. FYI.</p>}
                        </div>
                        <div className="flex justify-end items-center gap-2">
                          <button className="btn-danger">Cancel Trip</button>
                          {/* <button type="button" onClick={() => closeModal()} className="btn-primary">No</button> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>

      <ConfirmationModal
        isConfirmationModal={isConfirmationModalOpen}
        message={message}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      </Dialog>
    </Transition.Root>
  );
}
