import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useAuth from "../hooks/useAuth";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

export default function ErrorAlert(props) {
  const cancelButtonRef = useRef(null);
  const { onClose } = props;

  const handleAction = (val) => {
    // console.log(val);
    props.setErrorInfo({
      open: false,
      message: ''
    });
    // onClose(val);
  };
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => handleAction(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-main-light bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-container-base px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-main-danger-100">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-main-danger"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-main-dark"
                    >
                      Alert!
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-main-light">
                        {props.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex justify-center">
                  <button
                    type="button"
                    className="w-full sm:w-auto btn-danger sm:col-start-2"
                    onClick={() => handleAction(true)}
                  >
                    Close
                  </button>
                  {/* <button
                    type="button"
                    className="w-full sm:w-auto mt-3 btn-white sm:col-start-1 sm:mt-0"
                    onClick={() => handleAction(false)}
                    ref={cancelButtonRef}
                  >
                    No
                  </button> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
