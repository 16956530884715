import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useAuth from "../hooks/useAuth";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

export default function ConfirmationModal({isConfirmationModal,message, onConfirm, onCancel}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isConfirmationModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-main-light bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-container-base px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-main-danger-100">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-main-danger"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-main-dark"
                    >
                      <p>{message}</p>
                      
                    </Dialog.Title>
                    {/* <div className="mt-2">
                      <p className="text-sm text-main-light">
                        Logging out will end your current session and require
                        you to sign in again to access your account. Please
                        confirm your intention to logout by selecting the
                        appropriate option below.
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="w-full sm:w-auto btn-danger sm:col-start-2"
                    onClick={() => onConfirm()}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="w-full sm:w-auto mt-3 btn-white sm:col-start-1 sm:mt-0"
                    onClick={() => onCancel()}
                    ref={cancelButtonRef}
                  >
                    No
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
