/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RadioGroup, Switch } from "@headlessui/react";
import clsx from "clsx";
import StandardImage from "../assets/vehicles/standard.png";
import PremiumImage from "../assets/vehicles/premium.png";
import SUVImage from "../assets/vehicles/suv.png";
import WAVImage from "../assets/vehicles/wav.png";
import StretcherImage from "../assets/vehicles/stretcher.png";
import { useEffect } from "react";
import moment from "moment-timezone";
import useAuth from "../hooks/useAuth";
import { useParams } from "react-router-dom";
import { states, tripReasonList, PAYOR } from "../utils/common";
import SuccessAlert from "../components/SuccessAlert";
import GoogleAutoComplete from "../components/GoogleAutoComplete";
import FileUpload from "../components/FileUpload";
import Swal from "sweetalert2";
import AddonServices from "../components/AddonServices";
import MemberSearch from "../components/MemberSearch";
import TrisLegComponent from "../components/TrisLegComponent";
import StandingOrders from "../components/StandingOrders";
import AddressBoxComponent from "../components/AddressBoxComponent";
import ErrorAlert from "../components/ErrorAlert";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Create(props) {
  const vehicles = [
    { name: "Standard", imgUrl: StandardImage },
    { name: "Premium", imgUrl: PremiumImage },
    { name: "SUV", imgUrl: SUVImage },
    { name: "WAV", imgUrl: WAVImage },
    { name: "Stretcher", imgUrl: StretcherImage },
  ];

  const { id } = useParams();
  const [restrictRadius, setRestrictRadius] = useState(false);
  let [radius, setRadius] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const { user, getToken } = useAuth();
  const [addons, setAddons] = useState(null);
  const [drivers, setDrivers] = useState([]);
  let [pdfFile, setPdfFile] = useState(null);

  const [payors, setPayors] = useState(PAYOR); //Object pf Payor
  const [validationPayorList, setValidationPayorList] = useState([]); // Array of Payor(only titles)
  const [isPayorsAttached, setIsPayorAttached] = useState(false);
  const [currentTrip, setCurrentTrip] = useState();
  const [errorInfo, setErrorInfo] = useState({
    open: false,
    message: ''
  });

  // for looping
  const [getstandingOrderDays, setStandingOrderDays] = useState([
    { label: "Mon", day: 1, checked: false, driver: null },
    { label: "Tue", day: 2, checked: false, driver: null },
    { label: "Wed", day: 3, checked: false, driver: null },
    { label: "Thu", day: 4, checked: false, driver: null },
    { label: "Fri", day: 5, checked: false, driver: null },
    { label: "Sat", day: 6, checked: false, driver: null },
    { label: "Sun", day: 0, checked: false, driver: null },
  ]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      scheduleTime: new Date(),
      appointmentTime: new Date().setHours(new Date().getHours() + 1),
      jobOriginAddress: "",
      jobDestinationAddress: "",
      addonServices: [],
      tripRequirement: "WAV",
      isStandingOrder: false,
      isRecurring: false,
      recurringDays: [],
      state: "AK",
      payor: "medicaid",
      tripReason: "Other AMB",
      isFileRequired: "false",
      payorPhoneNumber: "",
    },
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    let myPayors;

    if (user.cooperate) {
      myPayors = user.cooperate.payorTypes;
    } else {
      myPayors = user.payorTypes;
    }

    if (myPayors && myPayors.length) {
      if (myPayors[0] && myPayors[0].title) {
        setIsPayorAttached(true);
        const payors = myPayors.reduce(
          (a, v) => ({ ...a, [v.value]: v.title }),
          {}
        );
        setPayors(payors);
        setValue("payor", Object.keys(payors).sort()[0]);

        const corpPayorsList = myPayors.map((p) => p.title);
        setValidationPayorList(corpPayorsList);
      }
    }
    const time = moment().tz(`${user.company.timeZone}`);

    setValue("scheduleTime", new Date(time.format("YYYY-MM-DD HH:mm:ss")));
    setValue(
      "appointmentTime",
      new Date(time.add(1, "hour").format("YYYY-MM-DD HH:mm:ss"))
    );

    // set Facility Address
    if (user.cooperate) {
      setValue("jobOriginAddress", user.cooperate.address);
      setValue("jobOriginLatitude", user.cooperate.latitude);
      setValue("jobOriginLongitude", user.cooperate.longitude);
      setValue("priorityClient.cellPhone", user.cooperate.contactNumber);
      radius = {
        miles: 200,
        center: { lat: user.cooperate.latitude, lng: user.cooperate.longitude },
      };
      setRadius(radius);
    } else {
      setValue("jobOriginAddress", user.address);
      setValue("jobOriginLatitude", user.latitude);
      setValue("jobOriginLongitude", user.longitude);
      setValue("priorityClient.cellPhone", user.contactNumber);
      radius = {
        miles: 200,
        center: { lat: user.latitude, lng: user.longitude },
      };
      setRadius(radius);
    }

    let defaultVehicle = vehicles[0].name;
    let isContainDefault = false;

    const myVehicles =
      user.cooperate && user.cooperate.vehicles
        ? user.cooperate.vehicles
        : user.vehicles;

    if (myVehicles && myVehicles.length) {
      const fVehicles = vehicles.filter((v) => myVehicles.includes(v.name));

      if (fVehicles.length) {
        if (myVehicles.includes(getValues().tripRequirement)) {
          isContainDefault = true;
        }

        defaultVehicle = fVehicles[0].name;
      } else {
        defaultVehicle = "";
      }
    } else {
      defaultVehicle = "";
    }

    if (isContainDefault) defaultVehicle = getValues().tripRequirement;

    setValue("tripRequirement", defaultVehicle);

    getDrivers();
    fetchData();
  }, [user]);

  const payorName = watch("payor");

  // Hooks End from here
  const setAppointmentTime = (date) => {
    const appointmentTime = new Date(date);
    appointmentTime.setHours(appointmentTime.getHours() + 1);
    setValue("appointmentTime", appointmentTime);
  };

  const getDrivers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}drivers/companies/${user.company._id}/active`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${JSON.parse(getToken())}`,
          },
        }
      );
      const data = await response.json();
      setDrivers(data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const fetchData = async () => {
    const corporateId = user.cooperate ? user.cooperate._id : user._id;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}addonservices/corporate?cooperate=${corporateId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${JSON.parse(getToken())}`,
          },
        }
      );

      const jsonData = await response.json();
      setAddons(jsonData);
      if (id) {
        getTripDetails();
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getTripDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}assigns/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${JSON.parse(getToken())}`,
          },
        }
      );

      const res = await response.json();
      setCurrentTrip(res);
      setFormData(res);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const setFormData = async (res) => {
    try {
      let data = {
        ...res,
        // dob: new Date(res.dob),
        scheduleTime: new Date(
          moment(res.scheduleTime)
            .tz(`${user.company.timeZone}`)
            .format("YYYY-MM-DD HH:mm:ss")
        ),
        appointmentTime: new Date(
          moment(res.appointmentTime)
            .tz(`${user.company.timeZone}`)
            .format("YYYY-MM-DD HH:mm:ss")
        ),
        recurringEndDate: res.recurringEndDate
          ? new Date(
              moment(res.recurringEndDate)
                .tz(`${user.company.timeZone}`)
                .format("YYYY-MM-DD HH:mm:ss")
            )
          : "",
      };

      getstandingOrderDays.forEach((so) => {
        const obj = data.recurringDays.find((rd) => rd.day === so.day);
        if (obj) {
          so.checked = true;
          so.driver = obj.driver;
        }
      });

      const updatedDays = [...getstandingOrderDays];
      const recurringDays = updatedDays.filter((day) => day.checked);
      reset(data);

      if (data.tripRequirement) {
        let defaultVehicle = vehicles[0].name;
        let isContainDefault = false;

        const userVehicles =
          user.cooperate && user.cooperate.vehicles
            ? user.cooperate.vehicles
            : user.vehicles;

        if (userVehicles && userVehicles.length) {
          const fVehicles = vehicles.filter((v) =>
            userVehicles.includes(v.name)
          );

          if (fVehicles.length) {
            if (userVehicles.includes(data.tripRequirement)) {
              isContainDefault = true;
            }
            defaultVehicle = fVehicles[0].name;
          } else {
            defaultVehicle = "";
          }
        } else {
          defaultVehicle = "";
        }

        if (isContainDefault) defaultVehicle = getValues().tripRequirement;

        setValue("tripRequirement", defaultVehicle);

        // const v = vehicles.find((v) => v.name === data.tripRequirement);
        // if (v) {
        //   setValue("tripRequirement", v.name);
        // } else {
        //   setValue("tripRequirement", vehicles[0].name);
        // }
      }

      setValue("recurringDays", recurringDays);
      setValue("isStandingOrder", res.isRecurring);

      setValue("isFileRequired", data.attachment ? "true" : "false");

      pdfFile = data.attachment;
      setPdfFile(data.attachment);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getAllVehicles = () => {
    let userVehicles;

    const myVehicles =
      user.cooperate && user.cooperate.vehicles
        ? user.cooperate.vehicles
        : user.vehicles;

    if (myVehicles && myVehicles.length) {
      userVehicles = vehicles.filter((v) => myVehicles.includes(v.name));
    } else {
      userVehicles = vehicles;
    }

    return userVehicles;
  };

  const checkMiles = async () => {
    let {
      tripRequirement,
      jobOriginAddress,
      jobOriginLatitude,
      jobOriginLongitude,
      jobDestinationAddress,
      jobDestinationLatitude,
      jobDestinationLongitude,
    } = watch();
    if (!user || !jobOriginAddress || !jobDestinationAddress) return;

    const myJobOriginLatitude =
      typeof jobOriginAddress === "object"
        ? jobOriginAddress.geometry.location.lat()
        : jobOriginLatitude;

    const myJobOriginLongitude =
      typeof jobOriginAddress === "object"
        ? jobOriginAddress.geometry.location.lng()
        : jobOriginLongitude;

    const myJobDestinationLatitude =
      typeof jobDestinationAddress === "object"
        ? jobDestinationAddress.geometry.location.lat()
        : jobDestinationLatitude;

    const myJobDestinationLongitude =
      typeof jobDestinationAddress === "object"
        ? jobDestinationAddress.geometry.location.lng()
        : jobDestinationLongitude;

    try {
      const payload = {
        jobOriginLatitude: myJobOriginLatitude,
        jobOriginLongitude: myJobOriginLongitude,
        jobDestinationLatitude: myJobDestinationLatitude,
        jobDestinationLongitude: myJobDestinationLongitude,
        jobCarType: tripRequirement,
        passengerNumber: 1,
        company: user.company._id,
        jobType: "cooperate",
        cooperate: user.cooperate ? user.cooperate._id : user._id,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}assigns/tempestimatedfare`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${JSON.parse(getToken())}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const jsonData = await response.json();
      if (jsonData) {
        setValue("mileage", jsonData.totalMile);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const onSubmit = async (data) => {
    const now = moment().tz(user.company.timeZone);
    const sch = moment(data.scheduleTime).tz(user.company.timeZone,true);
    var duration = moment.duration(sch.diff(now));
    var hours = duration.asHours();
    console.log(hours, duration, now.format(), sch.format())
    if (hours <= 1 && id === undefined && user.company._id === "5a3da16ee13cb405d2b7d788") {
      setErrorInfo({
        open: true,
        message: `Trip can't be scheduled within 1 hour or for past times. Contact the provider at ${user.company.extension} or ${user.company.email} for help.`
      });
      return;
    }


    let newPayload = {
      company: user.company._id,
      ...data,

      priorityClient: {
        ...data.priorityClient,
        displayName: data.priorityClient.displayName.toUpperCase(),
      },
      // dob: moment(data.dob, moment.ISO_8601).format("MM/DD/YYYY"),

      jobOriginAddress:
        typeof data.jobOriginAddress === "object"
          ? data.jobOriginAddress.formatted_address
          : data.jobOriginAddress,
      jobOriginLatitude:
        typeof data.jobOriginAddress === "object"
          ? data.jobOriginAddress.geometry.location.lat()
          : data.jobOriginLatitude,
      jobOriginLongitude:
        typeof data.jobOriginAddress === "object"
          ? data.jobOriginAddress.geometry.location.lng()
          : data.jobOriginLongitude,

      jobDestinationAddress:
        typeof data.jobDestinationAddress === "object"
          ? data.jobDestinationAddress.formatted_address
          : data.jobDestinationAddress,
      jobDestinationLatitude:
        typeof data.jobDestinationAddress === "object"
          ? data.jobDestinationAddress.geometry.location.lat()
          : data.jobDestinationLatitude,
      jobDestinationLongitude:
        typeof data.jobDestinationAddress === "object"
          ? data.jobDestinationAddress.geometry.location.lng()
          : data.jobDestinationLongitude,

      scheduleTime: moment(data.scheduleTime)
        .tz(`${user.company.timeZone}`, true)
        .toISOString(),
      appointmentTime: moment(data.appointmentTime)
        .tz(`${user.company.timeZone}`, true)
        .toISOString(),

      companyType: "",

      jobCarType: data.tripRequirement,
      jobType: "cooperate",
      rawId: "",
      isMedical: false,
      cooperate: user.cooperate ? user.cooperate._id : user._id,
      customerSpecialRate: "",
      discount: "",
      fuelSurcharge: "",
      payerAuthorizer: "",
      // priorityClient: { ...data.priorityClient, clientId: "" },

      isStandingOrder: false,
      isRecurring: data.isStandingOrder,
      attachment: pdfFile ? pdfFile : "",
      viewStatus: id ? "updated" : "created",
    };

    if (!id) {
      newPayload = {
        ...newPayload,
        jobStatus: "pending",
        status: "unassigned",
        createdBy: user.displayName,
      };
    }

    if (newPayload.tripLegs && newPayload.tripLegs.length) {
      const tripLegs = data.tripLegs.map((leg) => {
        const scheduleTime = moment(leg.scheduleTime)
          .tz(`${user.company.timeZone}`, true)
          .toISOString();

        const origin = leg.jobOriginAddress;

        if (typeof origin === "object") {
          leg.jobOriginAddress = origin.formatted_address;
          leg.jobOriginLatitude = origin.geometry.location.lat();
          leg.jobOriginLongitude = origin.geometry.location.lng();
        }

        const destination = leg.jobDestinationAddress;

        if (typeof destination === "object") {
          leg.jobDestinationAddress = destination.formatted_address;
          leg.jobDestinationLatitude = destination.geometry.location.lat();
          leg.jobDestinationLongitude = destination.geometry.location.lng();
        }

        return {
          ...leg,
          scheduleTime,
        };
      });

      newPayload["tripLegs"] = tripLegs;
    } else {
      delete newPayload.tripLegs;
    }

    if (data.isStandingOrder) {
      const rEndDate = data.recurringEndDate
        ? moment(data.recurringEndDate)
            .tz(`${user.company.timeZone}`, true)
            .toISOString()
        : "";
      newPayload.recurringEndDate = rEndDate;
      newPayload.recurringDays = data.recurringDays;
      if (data.recurringDays.length) {
        newPayload.status = "standing";
        newPayload.jobStatus = "standing";
      }
    } else if (
      currentTrip &&
      currentTrip.status === "standing" &&
      !data.isStandingOrder
    ) {
      const rEndDate = data.recurringEndDate
        ? moment(data.recurringEndDate)
            .tz(`${user.company.timeZone}`, true)
            .toISOString()
        : "";
      newPayload.recurringEndDate = rEndDate;
      delete newPayload.recurringDays;
    } else {
      newPayload.recurringEndDate = "";
      delete newPayload.recurringDays;
    }

    // If enabled, one day must be selected
    if (data.isStandingOrder && data.recurringDays.length === 0) {
      return;
    }

    setDispatchNotes(newPayload);
    setTripNotes(newPayload);

    if (newPayload.tripLegs && newPayload.tripLegs.length) {
      setTripLegsNotes(newPayload);
    }

    if (user && !user.showScheduleTime) {
      if (
        [
          ...validationPayorList,
          PAYOR.optum,
          PAYOR.privatePay,
          PAYOR.billFacility,
          PAYOR.medicaid,
        ].includes(payors[newPayload.payor])
      ) {
        newPayload.scheduleTime = moment(newPayload.appointmentTime)
          .subtract(1, "hour")
          .toISOString();
      }
    }

    saveTrip(newPayload);
  };

  const saveTrip = (payload) => {
    const token = JSON.parse(getToken());

    let url = "";
    if (id) {
      url = `${process.env.REACT_APP_BASEURL}assigns/${id}`;
    } else {
      url = `${process.env.REACT_APP_BASEURL}assigns`;
    }

    fetch(url, {
      method: id ? "PUT" : "POST",
      headers: {
        Authorization: "JWT " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setOpen(true);
          setMessage(id ? "Edited" : "Created");
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    // If the selected date is today, then filter out the times that have passed.
    return currentDate.getDate() === selectedDate.getDate()
      ? selectedDate.getTime() > currentDate.getTime()
      : true;
  };

  const setAutoState = (place) => {
    const address = place.address_components;
    address.forEach((component) => {
      if (component.types && component.types.length) {
        if (component.types.includes("administrative_area_level_1")) {
          if (component && component.short_name)
            setValue("state", component.short_name);
        }
      }
    });
    checkMiles();
  };

  const setTripLegsNotes = (newPayload) => {
    if (newPayload.tripLegs[0].companyNote) {
      let n = newPayload.tripLegs[0].companyNote.split(
        "<Add comment before this|>"
      );
      if (n.length) {
        newPayload.tripLegs[0].companyNote = n[0];
      }
    }
    // }
    if (newPayload.tripLegs[0].companyNote) {
      newPayload.tripLegs[0].companyNote =
        newPayload.tripLegs[0].companyNote.trim() +
        ` <Add comment before this|> \n`;
    } else {
      newPayload.tripLegs[0].companyNote = `<Add comment before this|> \n`;
    }

    if (newPayload.addonServices && newPayload.addonServices.length) {
      newPayload.tripLegs[0].companyNote += `\nAddon Services`;
      for (let addons of newPayload.addonServices) {
        newPayload.tripLegs[0].companyNote +=
          (newPayload.tripLegs[0].companyNote.length ? `\n` : "") +
          `${addons.title} * ${addons.quantity}`;
      }
      newPayload.tripLegs[0].companyNote += `\n`;
    }

    if (newPayload.tripLegs[0].originAddressAdditional) {
      if (
        newPayload.tripLegs[0].originAddressAdditional.floor &&
        newPayload.tripLegs[0].originAddressAdditional.floor.length
      ) {
        newPayload.tripLegs[0].companyNote +=
          (newPayload.tripLegs[0].companyNote.length ? `\n` : "") +
          `Origin Address Additional\n`;
        newPayload.tripLegs[0].companyNote += `Floor :  ${newPayload.tripLegs[0].originAddressAdditional.floor} \n`;
      }

      if (
        newPayload.tripLegs[0].originAddressAdditional.room &&
        newPayload.tripLegs[0].originAddressAdditional.room.length
      ) {
        newPayload.tripLegs[0].companyNote += `Room :  ${newPayload.tripLegs[0].originAddressAdditional.room} \n`;
      }

      if (
        newPayload.tripLegs[0].originAddressAdditional.aptOrSuite &&
        newPayload.tripLegs[0].originAddressAdditional.aptOrSuite.length
      ) {
        newPayload.tripLegs[0].companyNote += `Apt or Suite :  ${newPayload.tripLegs[0].originAddressAdditional.aptOrSuite}\n`;
      }

      // if (
      //   newPayload.tripLegs[0].originAddressAdditional.place &&
      //   newPayload.tripLegs[0].originAddressAdditional.place.length
      // ) {
      //   newPayload.tripLegs[0].companyNote += `Home/Facility/Physician Name :  ${newPayload.tripLegs[0].originAddressAdditional.place}\n`;
      // }

      // if (
      //   newPayload.tripLegs[0].originAddressAdditional.phone &&
      //   newPayload.tripLegs[0].originAddressAdditional.phone.length
      // ) {
      //   newPayload.tripLegs[0].companyNote += `Home/Facility/Physician Phone :  ${newPayload.tripLegs[0].originAddressAdditional.phone}\n`;
      // }
    }

    if (newPayload.tripLegs[0].destinationAddressAdditional) {
      if (
        newPayload.tripLegs[0].destinationAddressAdditional.floor &&
        newPayload.tripLegs[0].destinationAddressAdditional.floor.length
      ) {
        newPayload.tripLegs[0].companyNote +=
          (newPayload.tripLegs[0].companyNote.length ? `\n` : "") +
          `Destination Address Additional\n`;
        newPayload.tripLegs[0].companyNote += `Floor :  ${newPayload.tripLegs[0].destinationAddressAdditional.floor} \n`;
      }

      if (
        newPayload.tripLegs[0].destinationAddressAdditional.room &&
        newPayload.tripLegs[0].destinationAddressAdditional.room.length
      ) {
        newPayload.tripLegs[0].companyNote += `Room :  ${newPayload.tripLegs[0].destinationAddressAdditional.room} \n`;
      }

      if (
        newPayload.tripLegs[0].destinationAddressAdditional.aptOrSuite &&
        newPayload.tripLegs[0].destinationAddressAdditional.aptOrSuite.length
      ) {
        newPayload.tripLegs[0].companyNote += `Apt or Suite :  ${newPayload.tripLegs[0].destinationAddressAdditional.aptOrSuite}\n`;
      }

      // if (
      //   newPayload.tripLegs[0].destinationAddressAdditional.place &&
      //   newPayload.tripLegs[0].destinationAddressAdditional.place.length
      // ) {
      //   newPayload.tripLegs[0].companyNote += `Home/Facility/Physician Name :  ${newPayload.tripLegs[0].destinationAddressAdditional.place}\n`;
      // }

      // if (
      //   newPayload.tripLegs[0].destinationAddressAdditional.phone &&
      //   newPayload.tripLegs[0].destinationAddressAdditional.phone.length
      // ) {
      //   newPayload.tripLegs[0].companyNote += `Home/Facility/Physician Phone :  ${newPayload.tripLegs[0].destinationAddressAdditional.phone}\n`;
      // }
    }

    // if (newPayload.weight) {
    //   newPayload.tripLegs[0].companyNote +=
    //     (newPayload.tripLegs[0].companyNote.length ? `\n` : "") +
    //     `Weight :  ${newPayload.weight} lbs`;
    // }

    // if (newPayload.memberId) {
    //   newPayload.tripLegs[0].companyNote += (newPayload.tripLegs[0].companyNote.length ? `\n` : "") + `Insurance Id :  ${newPayload.memberId}`
    // }

    // if (newPayload.dob) {
    //   newPayload.tripLegs[0].companyNote += (newPayload.tripLegs[0].companyNote.length ? `\n` : "") + `DOB : ${newPayload.dob}`
    // }

    // if (newPayload.priorityClient && newPayload.priorityClient.email) {
    //   newPayload.tripLegs[0].companyNote += (newPayload.tripLegs[0].companyNote.length ? `\n` : '') + `Email : ${newPayload.priorityClient.email}`
    // }
  };

  const setTripNotes = (newPayload) => {
    // if (id) {
    if (newPayload.companyNote) {
      let n = newPayload.companyNote.split("<Add comment before this|>");
      if (n.length) {
        newPayload.companyNote = n[0];
      }
    }
    // }
    if (newPayload.companyNote) {
      newPayload.companyNote =
        newPayload.companyNote.trim() + ` <Add comment before this|> \n`;
    } else {
      newPayload.companyNote = `<Add comment before this|> \n`;
    }

    if (newPayload.addonServices && newPayload.addonServices.length) {
      newPayload.companyNote += `\nAddon Services`;
      for (let addons of newPayload.addonServices) {
        newPayload.companyNote +=
          (newPayload.companyNote.length ? `\n` : "") +
          `${addons.title} * ${addons.quantity}`;
      }
      newPayload.companyNote += `\n`;
    }

    if (newPayload.originAddressAdditional) {
      if (
        newPayload.originAddressAdditional.floor &&
        newPayload.originAddressAdditional.floor.length
      ) {
        newPayload.companyNote +=
          (newPayload.companyNote.length ? `\n` : "") +
          `Origin Address Additional\n`;
        newPayload.companyNote += `Floor :  ${newPayload.originAddressAdditional.floor} \n`;
      }

      if (
        newPayload.originAddressAdditional.room &&
        newPayload.originAddressAdditional.room.length
      ) {
        newPayload.companyNote += `Room :  ${newPayload.originAddressAdditional.room} \n`;
      }

      if (
        newPayload.originAddressAdditional.aptOrSuite &&
        newPayload.originAddressAdditional.aptOrSuite.length
      ) {
        newPayload.companyNote += `Apt or Suite :  ${newPayload.originAddressAdditional.aptOrSuite}\n`;
      }

      // if (
      //   newPayload.originAddressAdditional.place &&
      //   newPayload.originAddressAdditional.place.length
      // ) {
      //   newPayload.companyNote += `Home/Facility/Physician Name :  ${newPayload.originAddressAdditional.place}\n`;
      // }

      // if (
      //   newPayload.originAddressAdditional.phone &&
      //   newPayload.originAddressAdditional.phone.length
      // ) {
      //   newPayload.companyNote += `Home/Facility/Physician Phone :  ${newPayload.originAddressAdditional.phone}\n`;
      // }
    }

    if (newPayload.destinationAddressAdditional) {
      if (
        newPayload.destinationAddressAdditional.floor &&
        newPayload.destinationAddressAdditional.floor.length
      ) {
        newPayload.companyNote +=
          (newPayload.companyNote.length ? `\n` : "") +
          `Destination Address Additional\n`;
        newPayload.companyNote += `Floor :  ${newPayload.destinationAddressAdditional.floor} \n`;
      }

      if (
        newPayload.destinationAddressAdditional.room &&
        newPayload.destinationAddressAdditional.room.length
      ) {
        newPayload.companyNote += `Room :  ${newPayload.destinationAddressAdditional.room} \n`;
      }

      if (
        newPayload.destinationAddressAdditional.aptOrSuite &&
        newPayload.destinationAddressAdditional.aptOrSuite.length
      ) {
        newPayload.companyNote += `Apt or Suite :  ${newPayload.destinationAddressAdditional.aptOrSuite}\n`;
      }

      // if (
      //   newPayload.destinationAddressAdditional.place &&
      //   newPayload.destinationAddressAdditional.place.length
      // ) {
      //   newPayload.companyNote += `Home/Facility/Physician Name :  ${newPayload.destinationAddressAdditional.place}\n`;
      // }

      // if (
      //   newPayload.destinationAddressAdditional.phone &&
      //   newPayload.destinationAddressAdditional.phone.length
      // ) {
      //   newPayload.companyNote += `Home/Facility/Physician Phone :  ${newPayload.destinationAddressAdditional.phone}\n`;
      // }
    }

    // if (newPayload.weight) {
    //   newPayload.companyNote +=
    //     (newPayload.companyNote.length ? `\n` : "") +
    //     `Weight :  ${newPayload.weight} lbs`;
    // }

    // if (newPayload.memberId) {
    //   newPayload.companyNote += (newPayload.companyNote.length ? `\n` : "") + `Insurance Id :  ${newPayload.memberId}`
    // }

    // if (newPayload.dob) {
    //   newPayload.companyNote += (newPayload.companyNote.length ? `\n` : "") + `DOB : ${newPayload.dob}`
    // }

    // if (newPayload.priorityClient && newPayload.priorityClient.email) {
    //   newPayload.companyNote += (newPayload.companyNote.length ? `\n` : '') + `Email : ${newPayload.priorityClient.email}`
    // }
  };

  const setDispatchNotes = (newPayload) => {
    if (newPayload.dispatchNoteTwo) {
      let c = newPayload.dispatchNoteTwo.split("<Add comment before this|>");
      if (c.length) {
        newPayload.dispatchNoteTwo = c[0];
      }
    }
    // }
    // added adition
    if (newPayload.dispatchNoteTwo) {
      newPayload.dispatchNoteTwo =
        newPayload.dispatchNoteTwo.trim() + ` <Add comment before this|> \n`;
    } else {
      newPayload.dispatchNoteTwo = `<Add comment before this|> \n`;
    }

    if (newPayload.payor) {
      newPayload.dispatchNoteTwo +=
        (newPayload.dispatchNoteTwo.length ? `\n` : "") +
        `Payor : ${newPayload.payor}`;
    }

    if (newPayload.payorPhoneNumber) {
      newPayload.dispatchNoteTwo +=
        (newPayload.dispatchNoteTwo.length ? `\n` : "") +
        `Payor Phone Number : ${newPayload.payorPhoneNumber}`;
    }

    if (newPayload.responsiblePaymentPerson) {
      newPayload.dispatchNoteTwo +=
        (newPayload.dispatchNoteTwo.length ? `\n` : "") +
        `Responsible Payment Person : ${newPayload.responsiblePaymentPerson}`;
    }

    newPayload.dispatchNoteTwo +=
      (newPayload.dispatchNoteTwo.length ? `\n` : "") +
      `${id ? "Updated By" : "Created By"} : ${user.displayName} ${moment()
        .tz(`${user.company.timeZone}`)
        .format("MM/DD/YY HHmm")}`;
  };

  const isShowFormField = (formKey) => {
    let isShow = true;
    if (formKey === "dob") {
      if (validationPayorList.length) {
        if ([payors.devHospital].includes(payors[payorName])) {
        }
      } else {
        if (
          [PAYOR.privatePay, PAYOR.billFacility].includes(payors[payorName])
        ) {
          isShow = false;
        }
      }
    }

    return isShow;
  };

  const closeErrorAlert = (val) => {
    setErrorInfo({
      open: false,
      message: ''
    })
  }

  return (
    <>
      <div className="relative z-0 overflow-y-auto">
        <div className="px-4 sm:px-6 lg:px-8 pt-4 pb-16 bg-main-base-100 gap-4 flex flex-col lg:flex-row flex-1 ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="card flex-1 divide-y divide-main-base flex flex-col gap-8"
          >
            <div>
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <h3 className="sm:col-span-4 text-lg font-medium leading-6 text-main-dark">
                  {id ? "Edit" : "Create"}
                </h3>
                <div className="sm:col-span-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="gender"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      <span
                        className={`after:content-['*'] after:ml-0.5 after:text-red-500`}
                      >
                        Select Payor
                      </span>
                      <span className="ml-1 text-main-primary/90 font-normal">
                        (Required)
                      </span>
                    </label>
                    <div className="mt-1">
                      <select
                        id="payor"
                        {...register("payor", {
                          required: true,
                        })}
                      >
                        {Object.keys(payors)
                          .sort()
                          .map((item, index) => (
                            <option key={item} value={item}>
                              {payors[item]}
                            </option>
                          ))}
                      </select>
                    </div>
                    {errors.payor && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the payor
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="clientName"
                    className=" block text-sm font-medium text-main-secondary"
                  >
                    <span
                      className={`after:content-['*'] after:ml-0.5 after:text-red-500`}
                    >
                      Client Name
                    </span>
                    <span className="ml-1 text-main-primary/90 font-normal">
                      (First & Last Name Required)
                    </span>
                  </label>
                  <div className="mt-1">
                    <input
                      id="displayName"
                      name="displayName"
                      type="text"
                      {...register("priorityClient.displayName", {
                        required: true,
                      })}
                    />
                  </div>
                  {errors?.priorityClient?.displayName && (
                    <p className="mt-2 text-xs text-main-danger">
                      Enter the Client Name
                    </p>
                  )}
                </div>

                {![PAYOR.privatePay, PAYOR.billFacility].includes(
                  payors[payorName]
                ) && (
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="dateOfBirth"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      <span
                        className={
                          [PAYOR.medicaid, PAYOR.optum].includes(
                            payors[payorName]
                          )
                            ? `after:content-['*'] after:ml-0.5 after:text-red-500`
                            : ""
                        }
                      >
                        DOB
                      </span>
                      <span className="ml-1 text-main-primary/90 font-normal">
                        {[PAYOR.medicaid, PAYOR.optum].includes(
                          payors[payorName]
                        )
                          ? `(Required)`
                          : ""}
                      </span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        placeholder="MM/DD/YYYY"
                        {...register("dob", {
                          required: [PAYOR.medicaid, PAYOR.optum].includes(
                            payors[payorName]
                          )
                            ? true
                            : false,
                        })}
                      />
                      {/* <Controller
                    control={control}
                    rules={{ required: false }}
                    name="dob"
                    render={({ field }) => (
                      <DatePicker
                        placeholderText="Select date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        maxDate={new Date()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="MM/dd/yyyy"
                      />
                    )}
                  /> */}
                    </div>
                    {errors.dob && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the DOB
                      </p>
                    )}
                  </div>
                )}

                {![
                  PAYOR.optum,
                  PAYOR.medicaid,
                  PAYOR.privatePay,
                  PAYOR.billFacility,
                ].includes(payors[payorName]) && (
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="weight"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      Weight{" "}
                      <span className="text-main-primary/90 font-normal">
                        (Optional)
                      </span>
                    </label>
                    <div className="mt-1 flex gap-px">
                      <div className="relative flex flex-grow items-stretch">
                        <input
                          type="number"
                          name="weight"
                          id="weight"
                          className="rounded-l-md rounded-none w-full"
                          defaultValue={0}
                          {...register("weight", {
                            valueAsNumber: true,
                            required: false,
                          })}
                        />
                      </div>
                      <div className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-xs text-main-dark ring-1 ring-inset ring-main-base bg-gray-50">
                        lbs
                      </div>
                    </div>
                    {errors.weight && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Weight
                      </p>
                    )}
                  </div>
                )}

                {![PAYOR.optum, PAYOR.privatePay, PAYOR.billFacility].includes(
                  payors[payorName]
                ) && (
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="memberId"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      <span
                        className={
                          [PAYOR.medicaid].includes(payors[payorName])
                            ? `after:content-['*'] after:ml-0.5 after:text-red-500`
                            : ""
                        }
                      >
                        Insurance ID / Leg ID / W#
                      </span>
                      {[PAYOR.medicaid].includes(payors[payorName]) && (
                        <span className="ml-1 text-main-primary/90 font-normal">
                          (Required)
                        </span>
                      )}
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("memberId", {
                          required: [PAYOR.medicaid].includes(payors[payorName])
                            ? true
                            : false,
                        })}
                      />
                    </div>
                    {errors.memberId && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Insurance ID
                      </p>
                    )}
                  </div>
                )}

                {![PAYOR.privatePay, PAYOR.billFacility].includes(
                  payors[payorName]
                ) && (
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="clientId"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      <span
                        className={
                          [PAYOR.medicaid, PAYOR.optum].includes(
                            payors[payorName]
                          )
                            ? `after:content-['*'] after:ml-0.5 after:text-red-500`
                            : ""
                        }
                      >
                        Member ID / MA# / AHCCCS# / Optum#
                      </span>
                      {[PAYOR.medicaid, PAYOR.optum].includes(
                        payors[payorName]
                      ) && (
                        <span className="ml-1 text-main-primary/90 font-normal">
                          (Required)
                        </span>
                      )}
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("priorityClient.clientId", {
                          required: [PAYOR.medicaid, PAYOR.optum].includes(
                            payors[payorName]
                          )
                            ? true
                            : false,
                        })}
                      />
                    </div>
                    {errors?.priorityClient?.clientId && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Member ID / MA# / AHCCCS# / Optum#
                      </p>
                    )}
                  </div>
                )}

                {![
                  PAYOR.optum,
                  PAYOR.medicaid,
                  PAYOR.privatePay,
                  PAYOR.billFacility,
                ].includes(payors[payorName]) && (
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="ipa"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      IPA / FECA Number{" "}
                      <span className="text-main-primary/90 font-normal">
                        (Optional)
                      </span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("ipa", { required: false })}
                      />
                    </div>
                    {errors.ipa && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Client IPA / FECA Number
                      </p>
                    )}
                  </div>
                )}

                <div className="sm:col-span-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="contactNumber"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      <span
                        className={`after:content-['*'] after:ml-0.5 after:text-red-500`}
                      >
                        Phone Number
                      </span>
                      <span className="ml-1 text-main-primary/90 font-normal">
                        (Required)
                      </span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="tel"
                        id="contactNumber"
                        {...register("priorityClient.contactNumber", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors?.priorityClient?.contactNumber && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Phone Number
                      </p>
                    )}
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="cellNumber"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      Cell Number{" "}
                      <span className="text-main-primary/90 font-normal">
                        (Optional)
                      </span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="tel"
                        id="cellPhone"
                        {...register("priorityClient.cellPhone", {
                          required: false,
                        })}
                      />
                    </div>
                    {errors?.priorityClient?.cellPhone && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Cell Number
                      </p>
                    )}
                  </div>

                  {[PAYOR.privatePay].includes(payors[payorName]) && (
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="payorPhoneNumber"
                        className="block text-sm font-medium text-main-secondary"
                      >
                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500">
                          Payor Phone Number
                        </span>
                        <span className="ml-1 text-main-primary/90 font-normal">
                          (Required)
                        </span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="tel"
                          id="cellPhone"
                          {...register("payorPhoneNumber", {
                            required: true,
                          })}
                        />
                      </div>
                      {errors?.payorPhoneNumber && (
                        <p className="mt-2 text-xs text-main-danger">
                          Enter the Payor Phone Number
                        </p>
                      )}
                    </div>
                  )}

                  {[PAYOR.privatePay].includes(payors[payorName]) && (
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="payorPhoneNumber"
                        className="block text-sm font-medium text-main-secondary"
                      >
                        <span className="after:content-['*'] after:ml-0.5 after:text-red-500">
                          Responsible Payment Person
                        </span>
                        <span className="ml-1 text-main-primary/90 font-normal">
                          (Required)
                        </span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="tel"
                          id="cellPhone"
                          {...register("responsiblePaymentPerson", {
                            required: true,
                          })}
                        />
                      </div>
                      {errors?.responsiblePaymentPerson && (
                        <p className="mt-2 text-xs text-main-danger">
                          Enter the Responsible Payment Person
                        </p>
                      )}
                    </div>
                  )}

                  {user && user.selfPay && (
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-main-secondary"
                      >
                        <span
                          className={`after:content-['*'] after:ml-0.5 after:text-red-500`}
                        >
                          Email
                        </span>
                        <span className="ml-1 text-main-primary/90 font-normal">
                          (Required)
                        </span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          id="email"
                          {...register("priorityClient.email", {
                            required: true,
                          })}
                        />
                      </div>
                      {errors?.priorityClient?.email && (
                        <p className="mt-2 text-xs text-main-danger">
                          Enter the Email Address
                        </p>
                      )}
                    </div>
                  )}
                </div>

                {![
                  PAYOR.medicaid,
                  PAYOR.optum,
                  PAYOR.privatePay,
                  PAYOR.billFacility,
                ].includes(payors[payorName]) && (
                  <div className="sm:col-span-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="gender"
                        className="block text-sm font-medium text-main-secondary"
                      >
                        Gender
                      </label>
                      <div className="mt-1">
                        <select
                          id="gender"
                          {...register("gender", { required: false })}
                        >
                          <option value="">-- Select Gender --</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                      {errors.gender && (
                        <p className="mt-2 text-xs text-main-danger">
                          Enter the gender
                        </p>
                      )}
                    </div>
                  </div>
                )}

                <div className="sm:col-span-6 ">
                  <Switch.Group as="div" className="flex items-center">
                    <Switch
                      checked={restrictRadius}
                      onChange={setRestrictRadius}
                      className={classNames(
                        restrictRadius ? "bg-main-primary" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-main-primary focus:ring-offset-2"
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        className={classNames(
                          restrictRadius ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      >
                        <span
                          className={classNames(
                            restrictRadius
                              ? "opacity-0 duration-100 ease-out"
                              : "opacity-100 duration-200 ease-in",
                            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                          )}
                          aria-hidden="true"
                        >
                          <svg
                            className="h-3 w-3 text-gray-400"
                            fill="none"
                            viewBox="0 0 12 12"
                          >
                            <path
                              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span
                          className={classNames(
                            restrictRadius
                              ? "opacity-100 duration-200 ease-in"
                              : "opacity-0 duration-100 ease-out",
                            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                          )}
                          aria-hidden="true"
                        >
                          <svg
                            className="h-3 w-3 text-main-primary"
                            fill="currentColor"
                            viewBox="0 0 12 12"
                          >
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                          </svg>
                        </span>
                      </span>
                    </Switch>
                    <Switch.Label as="span" className="ml-3 text-sm">
                      <span className="font-medium text-main-dark">
                        Restrict Search Radius (200mi)
                      </span>
                    </Switch.Label>
                  </Switch.Group>
                </div>

                {/* Pickup Address */}
                <AddressBoxComponent
                  lableName={"Pickup Address"}
                  addressFieldName={"jobOriginAddress"}
                  addressAdditionals={"originAddressAdditional"}
                  restrictRadius={restrictRadius}
                  radius={radius}
                  control={control}
                  setValue={setValue}
                  setAutoState={setAutoState}
                  register={register}
                  errors={errors}
                />

                {/* Dropoff Address */}
                <AddressBoxComponent
                  lableName={"Dropoff Address"}
                  addressFieldName={"jobDestinationAddress"}
                  addressAdditionals={"destinationAddressAdditional"}
                  restrictRadius={restrictRadius}
                  radius={radius}
                  setValue={setValue}
                  control={control}
                  setAutoState={setAutoState}
                  register={register}
                  errors={errors}
                />

                {/* Schedule Time */}
                {(![
                  PAYOR.optum,
                  PAYOR.privatePay,
                  PAYOR.billFacility,
                  PAYOR.medicaid,
                ].includes(payors[payorName]) ||
                  (user && user.showScheduleTime)) && (
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="scheduleTime"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      <span
                        className={`after:content-['*'] after:ml-0.5 after:text-red-500`}
                      >
                        Schedule Time
                      </span>
                      <span className="ml-1 text-main-primary/90 font-normal">
                        (Required)
                      </span>
                    </label>
                    <div className="mt-1">
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="scheduleTime"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            placeholderText="Select date"
                            showTimeSelect
                            // minDate={new Date()}
                            // filterTime={filterPassedTime}
                            // minTime={today}
                            // maxTime={today}
                            onChange={(date) => {
                              onChange(date);
                              setAppointmentTime(date);
                            }}
                            selected={value}
                            timeIntervals={15}
                            timeFormat="HH:mm"
                            dateFormat="MM/dd/yyyy HHmm"
                          />
                        )}
                      />
                    </div>
                    {errors.scheduleTime && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Pickup Date & Time
                      </p>
                    )}
                  </div>
                )}

                {/* Appointment Time */}
                <div className="sm:col-span-2">
                  <label
                    htmlFor="appointmentTime"
                    className="block text-sm font-medium text-main-secondary"
                  >
                    <span
                      className={`after:content-['*'] after:ml-0.5 after:text-red-500`}
                    >
                      Appointment Time
                    </span>
                    <span className="ml-1 text-main-primary/90 font-normal">
                      (Required)
                    </span>
                  </label>
                  <div className="mt-1">
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="appointmentTime"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          placeholderText="Select date"
                          showTimeSelect
                          // minDate={new Date()}
                          onChange={onChange}
                          selected={value}
                          timeIntervals={15}
                          timeFormat="HH:mm"
                          dateFormat="MM/dd/yyyy HHmm"
                        />
                      )}
                    />
                  </div>
                  {errors.appointmentTime && (
                    <p className="mt-2 text-xs text-main-danger">
                      Enter the Dropoff Date & Time
                    </p>
                  )}
                </div>

                {![
                  PAYOR.medicaid,
                  PAYOR.optum,
                  PAYOR.privatePay,
                  PAYOR.billFacility,
                ].includes(payors[payorName]) && (
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="mileage"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      Miles
                    </label>
                    <div className="mt-1  flex gap-px">
                      <div className="relative flex flex-grow items-stretch">
                        <input
                          type="number"
                          name="mileage"
                          step="any"
                          id="mileage"
                          className="rounded-l-md rounded-none w-full"
                          {...register("mileage", { required: false })}
                        />
                      </div>
                      <button
                        type="button"
                        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-xs text-white ring-1 ring-inset ring-main-primary bg-main-primary"
                        onClick={checkMiles}
                      >
                        Fetch
                      </button>
                    </div>
                    {errors.mileage && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Miles
                      </p>
                    )}
                  </div>
                )}

                {![
                  PAYOR.medicaid,
                  PAYOR.optum,
                  PAYOR.privatePay,
                  PAYOR.billFacility,
                ].includes(payors[payorName]) && (
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      <span
                        className={`after:content-['*'] after:ml-0.5 after:text-red-500`}
                      >
                        State
                      </span>
                      <span className="ml-1 text-main-primary/90 font-normal">
                        (Required)
                      </span>
                    </label>
                    <div className="mt-1">
                      <select
                        id="state"
                        {...register("state", { required: true })}
                      >
                        {states.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.state && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the State
                      </p>
                    )}
                  </div>
                )}

                {watch("tripReason") === "Other" && (
                  <div className="sm:col-span-3"></div>
                )}
                {![PAYOR.optum, PAYOR.privatePay, PAYOR.billFacility].includes(
                  payors[payorName]
                ) && (
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="tripReason"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      <span
                        className={
                          [PAYOR.medicaid].includes(payors[payorName])
                            ? `after:content-['*'] after:ml-0.5 after:text-red-500`
                            : ""
                        }
                      >
                        {[PAYOR.medicaid].includes(payors[payorName])
                          ? "Doctor Speciality"
                          : "Trip Purpose"}
                      </span>

                      {[PAYOR.medicaid].includes(payors[payorName]) && (
                        <span className="ml-1 text-main-primary/90 font-normal">
                          (Required)
                        </span>
                      )}
                    </label>
                    <div className="mt-1">
                      <select
                        id="tripReason"
                        {...register("tripReason", {
                          required: [PAYOR.medicaid].includes(payors[payorName])
                            ? true
                            : false,
                        })}
                      >
                        {tripReasonList.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.tripReason && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the{" "}
                        {[PAYOR.medicaid].includes(payors[payorName])
                          ? "Doctor Specialty"
                          : "Trip Purpose"}
                      </p>
                    )}
                  </div>
                )}

                {watch("tripReason") === "Other" && (
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="otherTripReason"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      <span
                        className={
                          watch("tripReason") === "Other"
                            ? `after:content-['*'] after:ml-0.5 after:text-red-500`
                            : ""
                        }
                      >
                        Other Purpose Name
                      </span>
                      {watch("tripReason") === "Other" && (
                        <span className="ml-1 text-main-primary/90 font-normal">
                          (Required)
                        </span>
                      )}
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("otherTripReason", {
                          required:
                            watch("tripReason") === "Other" ? true : false,
                        })}
                      />
                    </div>
                    {errors.otherTripReason && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Other Purpose Name
                      </p>
                    )}
                  </div>
                )}

                {![
                  PAYOR.medicaid,
                  PAYOR.optum,
                  PAYOR.privatePay,
                  PAYOR.billFacility,
                ].includes(payors[payorName]) && (
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="driverNotes"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      Driver Notes
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="driverNotes"
                        {...register("companyNote", { required: false })}
                        className=" h-16"
                      ></textarea>
                    </div>
                    {errors.companyNote && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Driver Notes
                      </p>
                    )}
                  </div>
                )}

                {![PAYOR.medicaid, PAYOR.optum, PAYOR.billFacility].includes(
                  payors[payorName]
                ) && (
                  <div
                    className={`${
                      [PAYOR.privatePay].includes(payors[payorName])
                        ? "sm:col-span-6"
                        : "sm:col-span-3"
                    }`}
                  >
                    <label
                      htmlFor="dispatchNoteTwo"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      Dispatcher Notes
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="dispatchNoteTwo"
                        {...register("dispatchNoteTwo", { required: false })}
                        className=" h-16"
                      ></textarea>
                    </div>
                    {errors.dispatchNoteTwo && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the Dispatcher Notes
                      </p>
                    )}
                  </div>
                )}

                {user && (
                  <div className="sm:col-span-6">
                    <label className="block text-sm font-medium text-main-secondary">
                      Select Vehicle
                    </label>
                    <div className="mt-1">
                      <Controller
                        name="tripRequirement"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <RadioGroup
                            value={value}
                            onChange={onChange}
                            className=""
                          >
                            <RadioGroup.Label className="sr-only">
                              Choose a memory option
                            </RadioGroup.Label>
                            <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                              {getAllVehicles().map((option, index) => (
                                <RadioGroup.Option
                                  key={index}
                                  value={option.name}
                                  className={({ checked }) =>
                                    clsx(
                                      checked
                                        ? "btn-primary justify-start"
                                        : "btn-white justify-start"
                                    )
                                  }
                                >
                                  <RadioGroup.Label
                                    as="div"
                                    className="flex items-center gap-2"
                                  >
                                    <img
                                      src={option.imgUrl}
                                      className="h-12 w-16 object-scale-down"
                                      alt=""
                                    />
                                    <div className="text-sm font-medium">
                                      {option.name}
                                    </div>
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        )}
                      />
                      {errors.tripRequirement && (
                        <p className="mt-2 text-xs text-main-danger">
                          Select vehicle or enable from provider portal.
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {![
                  PAYOR.medicaid,
                  PAYOR.optum,
                  PAYOR.privatePay,
                  PAYOR.billFacility,
                ].includes(payors[payorName]) && (
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="passenger"
                      className="block text-sm font-medium text-main-secondary"
                    >
                      <span
                        className={`after:content-['*'] after:ml-0.5 after:text-red-500`}
                      >
                        No. of Passenger
                      </span>
                      <span className="ml-1 text-main-primary/90 font-normal">
                        (Required)
                      </span>
                    </label>
                    <div className="mt-1">
                      <select
                        id="passenger"
                        {...register("jobPassengerNumber", { required: true })}
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                      </select>
                    </div>
                    {errors.jobPassengerNumber && (
                      <p className="mt-2 text-xs text-main-danger">
                        Enter the No. of Passenger
                      </p>
                    )}
                  </div>
                )}
                {![
                  PAYOR.medicaid,
                  PAYOR.optum,
                  PAYOR.privatePay,
                  PAYOR.billFacility,
                ].includes(payors[payorName]) && (
                  <AddonServices
                    addons={addons}
                    control={control}
                    register={register}
                    errors={errors}
                  />
                )}
              </div>
            </div>

            {/* Trip Legs */}
            {!id && (
              <TrisLegComponent
                watch={watch}
                setValue={setValue}
                control={control}
                errors={errors}
                register={register}
                restrictRadius={restrictRadius}
                radius={radius}
              />
            )}

            {/* Standing Orders */}
            {((id && currentTrip && currentTrip.status === "standing") ||
              !id) && (
              <StandingOrders
                currentTrip={currentTrip}
                getstandingOrderDays={getstandingOrderDays}
                watch={watch}
                setValue={setValue}
                control={control}
                errors={errors}
                register={register}
              />
            )}

            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-main-dark">
                  Attach File
                </h3>
                {[...validationPayorList, PAYOR.medicaid].includes(
                  payors[payorName]
                ) && (
                  <div className="flex flex-col mt-6">
                    <p className="text-sm font-medium text-main-danger">
                      Do you have an APPROVED Medicaid authorization form that
                      will be valid on the day of the appointment?
                    </p>
                    <div className="flex flex-wrap items-center gap-6 mt-3">
                      <div className="flex items-center gap-3">
                        <input
                          type="radio"
                          id="attachNo"
                          value="true"
                          name="attachFe"
                          {...register("isFileRequired")}
                        />
                        <label
                          htmlFor="attachYes"
                          className="text-sm font-medium text-main-secondary"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="flex items-center gap-3">
                        <input
                          type="radio"
                          id="attachNo"
                          value="false"
                          name="attachFe"
                          {...register("isFileRequired")}
                        />
                        <label
                          htmlFor="attachNo"
                          className="text-sm font-medium text-main-secondary"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {(([...validationPayorList, PAYOR.medicaid].includes(
                payors[payorName]
              ) &&
                watch("isFileRequired") === "true") ||
                ![PAYOR.medicaid].includes(payors[payorName])) && (
                <div className="mt-6 flex gap-4">
                  <FileUpload pdfFile={pdfFile} setPdfFile={setPdfFile} />
                </div>
              )}

              {[...validationPayorList, PAYOR.medicaid].includes(
                payors[payorName]
              ) &&
                watch("isFileRequired") === "true" &&
                !pdfFile && (
                  <p className="mt-2 text-xs text-main-danger">
                    Pdf File Required
                  </p>
                )}
            </div>

            {/* Create/Update */}
            <div className="pt-8">
              <div className="flex justify-end gap-4">
                <div>
                  <button type="submit" className="ml-3 btn-primary">
                    {id ? "Update" : "Create"}
                  </button>
                </div>
              </div>
            </div>
          </form>
          <MemberSearch
            watch={watch}
            setValue={setValue}
            reset={reset}
            vehicles={vehicles}
            addons={addons}
          />
        </div>
      </div>
      <SuccessAlert open={open} message={message} setOpen={setOpen} />
      <ErrorAlert open={errorInfo.open} message={errorInfo.message} setErrorInfo={setErrorInfo}  />
    </>
  );
}
